import React from "react";
import CheckBox from "./CheckBox";
import { useAppState } from "../AppContext";

const RootCause = () => {
  const { state, dispatch } = useAppState();

  return (
    <div className="mt-6 w-full space-y-2">
      <p className="italic font-semibold  text-base">Root Cause Analysis</p>
      <table className="w-full border-collapse border border-gray-400">
        <thead>
          <tr>
            <th className="border w-1/5 border-gray-400 p-2">#</th>
            <th className="border w-3/5 border-gray-400 p-2">Possible Cause</th>
            <th className="border w-1/5 border-gray-400 p-2">Percentage</th>
          </tr>
        </thead>
        <tbody>
          {state.root?.map((st, index) => (
            <tr key={index}>
              <td className="border border-gray-400 py-1 px-2">{st || ""}</td>
              <td className="border border-gray-400">
                <textarea
                  type="text"
                  value={state.possible_cause[index] || ""}
                  onChange={event => dispatch({type: "POSSIBLE_CAUSE", payload: {index1: index, value1: event.target.value}})}
                  className="w-full rounded py-1 px-2 h-20"
                />
              </td>
              <td className="border border-gray-400">
                <textarea
                  type="text"
                  value={state.percentage[index] || ""}
                  onChange={event => dispatch({type: "PERCENTAGE", payload: {index2: index, value2: event.target.value}})}
                  className="w-full rounded py-1 px-2 h-20"
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="flex gap-5">
        <p className="font-semibold">Course of Action(s) to be Undertaken (by Receipent) :</p>
        <div className="flex gap-2">
          <CheckBox change={() => dispatch({ type: "COURSE_OF_ACTION", payload: 1 })} check={state.course_of_action === 1} />
          <p>Corrective Action</p>
        </div>
        <div className="flex gap-2">
          <CheckBox change={() => dispatch({ type: "COURSE_OF_ACTION", payload: 2 })} check={state.course_of_action === 2} />
          <p>Preventive Action</p>
        </div>
      </div>
    </div>
  );
};

export default RootCause;
