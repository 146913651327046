import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useAppState } from "../AppContext";
import { SearchNcr } from "../Fetcher/searchNcr";
import { Tooltip } from "@material-tailwind/react";

const Navbar = () => {
  const { state, dispatch } = useAppState();
  const navigate = useNavigate();
  const user = JSON.parse(sessionStorage.getItem("user"));
  const [search, setSearch] = useState("");

  const handleSearch = async () => {
    const res = await SearchNcr(search, false);
    if (res) {
      dispatch({ type: "SET", payload: res });
      dispatch({ type: "DISABLE", payload: true });
      dispatch({ type: "EDITBUTTON", payload: true });
      navigate("/");
    }
  };

  const handleLogout = async () => {
    const res = await fetch("https://backendncr.ampectech.com/api/logout", {
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });

    const data = await res.json();

    localStorage.removeItem("token");
    sessionStorage.removeItem("user");
    navigate("/login");
  };

  return (
    <div className="fixed top-0 left-0 w-full bg-black h-14 flex items-center print:hidden z-10 justify-center px-10 text-sm">
      <div className="container flex justify-between">
        <div className="flex gap-10 items-center">
          <Tooltip
            content="Profile"
            placement="bottom"
            className="bg-gray-200 text-black"
          >
            <Link to="/profile" className="text-white">
              {user?.name || ""}
            </Link>
          </Tooltip>
          {sessionStorage.getItem("role") !== "admin" && (
            <Link
              to="/"
              onClick={() => {
                dispatch({ type: "RESET" });
                setSearch("");
              }}
              className="text-black bg-white font-medium py-1 px-4 rounded border hover:bg-transparent hover:text-white"
            >
              New Form
            </Link>
          )}
          {sessionStorage.getItem("role") === "admin" && (
            <div className="flex gap-5 items-center">
              <div className="flex gap-2">
                <input
                  onChange={(e) => setSearch(e.target.value)}
                  value={search || ""}
                  placeholder="@example: 000000"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") handleSearch();
                  }}
                  type="text"
                  className="border w-32 border-gray-100/40 bg-transparent rounded text-white p-1 placeholder:text-gray-100/30"
                />
                <button
                  onClick={handleSearch}
                  className="text-black bg-white font-medium py-1 px-4 rounded border hover:bg-transparent hover:text-white"
                >
                  Search
                </button>
              </div>
              <Link
                to="/"
                onClick={() => {
                  dispatch({ type: "RESET" });
                  setSearch("");
                }}
                className="text-black bg-white font-medium py-1 px-4 rounded border hover:bg-transparent hover:text-white"
              >
                New Form
              </Link>
              <Link
                to="/history"
                className="text-black bg-white font-medium py-1 px-4 rounded border hover:bg-transparent hover:text-white"
              >
                History
              </Link>
              <Link
                to="/trash"
                className="text-black bg-white font-medium py-1 px-4 rounded border hover:bg-transparent hover:text-white"
              >
                Trash
              </Link>
            </div>
          )}
        </div>
        <div className="flex gap-6">
          {sessionStorage.getItem("role") === "admin" && (
            <button
              className="text-white hover:text-yellow-400 hover:bg-white/10 rounded-lg px-2"
              onClick={() => navigate("/register")}
            >
              Register
            </button>
          )}
          <button
            className="hover:fill-yellow-400 fill-white hover:bg-gray-900 rounded-full p-2" // Change fill to text
            onClick={handleLogout}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 512 512"
            >
              <path d="M288 32c0-17.7-14.3-32-32-32s-32 14.3-32 32v224c0 17.7 14.3 32 32 32s32-14.3 32-32V32zm-144.5 88.6c13.6-11.3 15.4-31.5 4.1-45.1s-31.5-15.4-45.1-4.1C49.7 115.4 16 181.8 16 256c0 132.5 107.5 240 240 240s240-107.5 240-240c0-74.2-33.8-140.6-86.6-184.6c-13.6-11.3-33.8-9.4-45.1 4.1s-9.4 33.8 4.1 45.1c38.9 32.3 63.5 81 63.5 135.4c0 97.2-78.8 176-176 176s-176-78.8-176-176c0-54.4 24.7-103.1 63.5-135.4z" />
            </svg>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
