import React from "react";
import { Textarea } from "@material-tailwind/react";
import { useAppState } from "../AppContext";

const Cause = () => {
  const { state, dispatch } = useAppState();

  return (
    <div className="mt-6 w-full space-y-2">
      <p className="italic font-semibold  text-base">The Cause</p>
      <textarea
        placeholder="Cause"
        value={state.cause === 'null' ? "" : state.cause || ""}
        onChange={(e) => dispatch({ type: "CAUSE", payload: e.target.value })}
        className="text-blue-gray-700 border w-full rounded-md border-gray-400 p-2 focus:outline-pink-500 font-sans h-36 print:placeholder:invisible"
      />
    </div>
  );
};

export default Cause;
