import React, { useEffect } from "react";
import { Input } from "@material-tailwind/react";
import CheckBox from "./CheckBox";
import { useAppState } from "../AppContext";

const Header = () => {
  const { state, dispatch } = useAppState();

  useEffect(() => {
    if(sessionStorage.getItem("ncr") === null || sessionStorage.getItem("ncr") === undefined || sessionStorage.getItem("ncr") === ""){
      fetchNCRNumber();
    }
    else if(state.ncr === null || state.ncr === undefined || state.ncr === ""){
      dispatch({ type: "NCR", payload: sessionStorage.getItem("ncr") });
    }
  }, [state]);

  const fetchNCRNumber = async () => {
    const res = await fetch("https://backendncr.ampectech.com/api/generate-ncr");
    const data = await res.json();
    sessionStorage.setItem("ncr", data.ncr);
    dispatch({ type: "NCR", payload: data.ncr });
  };

  return (
    <div className="print:pt-0 pt-16">
      <div className="w-full flex gap-5">
        <div className="space-y-2 w-32">
          <p className="font-semibold pb-2">From</p>
          <div className="flex gap-2">
            <CheckBox
              change={() => {
                dispatch({ type: "FROM", payload: 1 });
                dispatch({ type: "REASON", payload: 0 });
                dispatch({ type: "OTHER", payload: "" });
              }}
              check={Number(state.from) === 1}
            />
            <p>Customer</p>
          </div>
          <div className="flex gap-2">
            <CheckBox
              change={() => {
                dispatch({ type: "FROM", payload: 2 });
                dispatch({ type: "REASON", payload: 0 });
                dispatch({ type: "OTHER", payload: "" });
              }}
              check={Number(state.from) === 2}
            />
            <p>External</p>
          </div>
          <div className="flex gap-2">
            <CheckBox
              change={() => {
                dispatch({ type: "FROM", payload: 3 });
                dispatch({ type: "REASON", payload: 0 });
                dispatch({ type: "OTHER", payload: "" });
              }}
              check={Number(state.from) === 3}
            />
            <p>Internal</p>
          </div>
        </div>
        <div className="space-y-2 flex-1">
          <div className="flex justify-between">
            <p className="font-semibold pb-2">Reason</p>
            <p className="font-semibold pb-2">
              NCR :{" "}
              {Number(state.from) === 1
                ? "C-"
                : Number(state.from) === 2
                ? "E-"
                : Number(state.from) === 3
                ? "I-"
                : ""}
              {state.ncr || ""}
            </p>
          </div>
          <div
            className={`flex justify-between ${
              Number(state.from) === 1 ? "" : "opacity-40 pointer-events-none"
            }`}
          >
            <div className="flex gap-2 w-40">
              <CheckBox
                change={() => dispatch({ type: "REASON", payload: 1 })}
                check={Number(state.reason) === 1 && Number(state.from) === 1}
              />
              <p>Product Complaint</p>
            </div>
            <div className="flex gap-2 w-48">
              <CheckBox
                change={() => dispatch({ type: "REASON", payload: 2 })}
                check={Number(state.reason) === 2 && Number(state.from) === 1}
              />
              <p>Improvement Suggestion</p>
            </div>
            <input value={Number(state.from) === 1 ? state.other === 'null' ? "" : state.other || '' : ''} onChange={(e) => dispatch({ type: "OTHER", payload: e.target.value})} placeholder="Other" type="text" className="w-40 border rounded border-gray-600 px-1" />
          </div>
          <div
            className={`flex justify-between ${
              Number(state.from) === 2 ? "" : "opacity-40 pointer-events-none"
            }`}
          >
            <div className="flex gap-2 w-40">
              <CheckBox
                change={() => dispatch({ type: "REASON", payload: 1 })}
                check={Number(state.reason) === 1 && Number(state.from) === 2}
              />
              <p>3rd Party Audit</p>
            </div>
            <div className="flex gap-2 w-48">
              <CheckBox
                change={() => dispatch({ type: "REASON", payload: 2 })}
                check={Number(state.reason) === 2 && Number(state.from) === 2}
              />
              <p>Supplier</p>
            </div>
            <input value={Number(state.from) === 2 ? state.other === 'null' ? "" : state.other || '' : ''} onChange={(e) => dispatch({ type: "OTHER", payload: e.target.value})} placeholder="Other" type="text" className="w-40 border rounded border-gray-600 px-1" />
          </div>
          <div
            className={`flex justify-between ${
              Number(state.from) === 3 ? "" : "opacity-40 pointer-events-none"
            }`}
          >
            <div className="flex gap-2 w-40">
              <CheckBox
                change={() => dispatch({ type: "REASON", payload: 1 })}
                check={Number(state.reason) === 1 && Number(state.from) === 3}
              />
              <p>Internal Audit</p>
            </div>
            <div className="flex gap-2 w-48">
              <CheckBox
                change={() => dispatch({ type: "REASON", payload: 2 })}
                check={Number(state.reason) === 2 && Number(state.from) === 3}
              />
              <p>Process Improvement</p>
            </div>
            <input value={Number(state.from) === 3 ? state.other === 'null' ? "" : state.other || '' : ''} onChange={(e) => dispatch({ type: "OTHER", payload: e.target.value})} placeholder="Other" type="text" className="w-40 border rounded border-gray-600 px-1" />
          </div>
        </div>
      </div>

      <div className="w-full mt-6 space-y-4">
        <div className="flex gap-5">
          <div className="w-1/2">
            <Input
              value={state.name === 'null' ? "" : state.name || ''}
              onChange={(e) =>
                dispatch({ type: "NAME", payload: e.target.value })
              }
              color="pink"
              label="Name / Department / Section"
              labelProps={
                {
                  className: `${state.name ? '' : 'print:peer-placeholder-shown:text-gray-100/40'}`,
                }
              }
            />
          </div>
          <div className="flex-1">
            <Input
              value={state.area === 'null' ? "" : state.area || ''}
              onChange={(e) =>
                dispatch({ type: "AREA", payload: e.target.value })
              }
              color="pink"
              label="Area / Reference Standard"
              labelProps={
                {
                  className: `${state.area ? '' : 'print:peer-placeholder-shown:text-gray-100/40'}`,
                }
              }
            />
          </div>
          <div className="flex-1">
            <Input
              value={state.issue_date === 'null' ? "" : state.issue_date || ''}
              onChange={(e) =>
                dispatch({ type: "ISSUE_DATE", payload: e.target.value })
              }
              className={`${state.issue_date ? '' : 'print:text-gray-100/40 text-gray-400'}`}
              type="date"
              color="pink"
              label="Issue Date"
            />
          </div>
        </div>
        <div className="flex gap-5">
          <div className="w-2/3">
            <Input
              value={state.subject === 'null' ? "" : state.subject || ''}
              onChange={(e) =>
                dispatch({ type: "SUBJECT", payload: e.target.value })
              }
              color="pink"
              label="Subject Matter"
              labelProps={
                {
                  className: `${state.subject ? '' : 'print:peer-placeholder-shown:text-gray-100/40'}`,
                }
              }
            />
          </div>
          <div className="flex-1">
            <Input
              type="date"
              className={`${state.response_required ? '' : 'print:text-gray-100/40 text-gray-400'}`}
              value={state.response_required === 'null' ? "" : state.response_required || ''}
              onChange={(e) =>
                dispatch({ type: "RESPONSE_REQUIRED", payload: e.target.value })
              }
              color="pink"
              label="Response is required before"
            />
          </div>
        </div>
        <div className="flex gap-5">
          <div className="flex-1">
            <Input
              value={state.product === 'null' ? "" : state.product || ''}
              onChange={(e) =>
                dispatch({ type: "PRODUCT", payload: e.target.value })
              }
              color="pink"
              label="Product"
              labelProps={
                {
                  className: `${state.product ? '' : 'print:peer-placeholder-shown:text-gray-100/40'}`,
                }
              }
            />
          </div>
          <div className="flex-1">
            <Input
              value={state.quantity === 'null' ? "" : state.quantity || ''}
              onChange={(e) =>
                dispatch({ type: "QUANTITY", payload: e.target.value })
              }
              color="pink"
              type="number"
              label="Quantity Involved"
              labelProps={
                {
                  className: `${state.quantity ? '' : 'print:peer-placeholder-shown:text-gray-100/40'}`,
                }
              }
            />
          </div>
          <div className="flex-1">
            <Input
              type="date"
              value={state.response_date === 'null' ? "" : state.response_date || ''}
              onChange={(e) =>
                dispatch({ type: "RESPONSE_DATE", payload: e.target.value })
              }
              color="pink"
              label="Response Date"
              className={`${state.response_date ? '' : 'print:text-gray-100/40 text-gray-400'}`}
            />
          </div>
        </div>
        <div className="flex gap-5">
          <div className="flex-1">
            <Input
              value={state.part_number === 'null' ? "" : state.part_number || ''}
              onChange={(e) =>
                dispatch({ type: "PART_NUMBER", payload: e.target.value })
              }
              color="pink"
              label="Part Number"
              labelProps={
                {
                  className: `${state.part_number ? '' : 'print:peer-placeholder-shown:text-gray-100/40'}`,
                }
              }
            />
          </div>
          <div className="flex-1">
            <Input
              value={state.stock_affected === 'null' ? "" : state.stock_affected || ''}
              onChange={(e) =>
                dispatch({ type: "STOCK_AFFECTED", payload: e.target.value })
              }
              color="pink"
              type="number"
              label="Stock Affected"
              labelProps={
                {
                  className: `${state.stock_affected ? '' : 'print:peer-placeholder-shown:text-gray-100/40'}`,
                }
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header; 