import React, { createContext, useContext, useReducer } from "react";

const initialState = {
  ncr: "",
  from: 0,
  reason: 0,
  other: "",
  other2: "",
  name: "",
  area: "",
  issue_date: "",
  subject: "",
  response_required: "",
  product: "",
  quantity: "",
  response_date: "",
  part_number: "",
  stock_affected: "",

  description: "",
  files: [],
  effect: "",
  nature_of_problem: [],
  administration_cost: "",
  material_cost: "",
  labour_cost: "",
  originator: "",
  recipient: "",
  department: "",
  company: "",

  cause: "",
  root: ["Man", "Process", "Machine", "Material", "Environment", "Design"],
  possible_cause: ["", "", "", "", "", ""],
  percentage: ["", "", "", "", "", ""],

  contain_problem: "",
  effectiveness: 0,
  reference: "",
  complete_date: "",
  further_action: "no",
  accepted_name: "",
  accepted_date: "",
  sign: "",

  preventive_problem: "",
  effectiveness2: 0,
  reference2: "",
  complete_date2: "",
  further_action2: "no",
  accepted_name2: "",
  accepted_date2: "",
  sign2: "",

  item: [1, 2],
  hazard: ["", ""],
  risk: ["", ""],
  risk_control: ["", ""],

  item2: [1, 2],
  likelihood: ["Rare", "Rare"],
  consequence: ["Insignificant", "Insignificant"],
  risk_rating: [6, 6],
  risk_level: ["Low Risk", "Low Risk"],
  accessed_by: "",
  accessed_date: "",

  course_of_action: 0,
  disable: false,
  editbutton: false,
  restore: false,
};

const AppContext = createContext(initialState);

// Define a reducer function to handle state updates
const reducer = (state, action) => {
  switch (action.type) {
    case "NCR":
      return { ...state, ncr: state.disable ? state.ncr : action.payload };
    case "FROM":
      return { ...state, from: state.disable ? state.from : action.payload };
    case "OTHER":
      return { ...state, other: state.disable ? state.other : action.payload };
    case "OTHER2":
      return { ...state, other2: state.disable ? state.other2 : action.payload };
    case "REASON":
      return {
        ...state,
        reason: state.disable ? state.reason : action.payload,
      };
    case "NAME":
      return { ...state, name: state.disable ? state.name : action.payload };
    case "AREA":
      return { ...state, area: state.disable ? state.area : action.payload };
    case "ISSUE_DATE":
      return {
        ...state,
        issue_date: state.disable ? state.issue_date : action.payload,
      };
    case "SUBJECT":
      return {
        ...state,
        subject: state.disable ? state.subject : action.payload,
      };
    case "RESPONSE_REQUIRED":
      return {
        ...state,
        response_required: state.disable
          ? state.response_required
          : action.payload,
      };
    case "PRODUCT":
      return {
        ...state,
        product: state.disable ? state.product : action.payload,
      };
    case "QUANTITY":
      return {
        ...state,
        quantity: state.disable ? state.quantity : action.payload,
      };
    case "RESPONSE_DATE":
      return {
        ...state,
        response_date: state.disable ? state.response_date : action.payload,
      };
    case "PART_NUMBER":
      return {
        ...state,
        part_number: state.disable ? state.part_number : action.payload,
      };
    case "STOCK_AFFECTED":
      return {
        ...state,
        stock_affected: state.disable ? state.stock_affected : action.payload,
      };

    case "DESCRIPTION":
      return {
        ...state,
        description: state.disable ? state.description : action.payload,
      };
    case "FILES":
      return { ...state, files: state.disable ? state.files : action.payload };
    case "EFFECT":
      return {
        ...state,
        effect: state.disable ? state.effect : action.payload,
      };
    case "NATURE_OF_PROBLEM":
      const { payload } = action;
      const newArray = [...state.nature_of_problem];
      const index = newArray.indexOf(payload);

      if (index !== -1) {
        newArray.splice(index, 1);
      } else {
        newArray.push(payload);
      }
      return {
        ...state,
        nature_of_problem: state.disable ? state.nature_of_problem : newArray,
      };
    case "ADMINISTRATION_COST":
      return {
        ...state,
        administration_cost: state.disable
          ? state.administration_cost
          : action.payload,
      };
    case "MATERIAL_COST":
      return {
        ...state,
        material_cost: state.disable ? state.material_cost : action.payload,
      };
    case "LABOUR_COST":
      return {
        ...state,
        labour_cost: state.disable ? state.labour_cost : action.payload,
      };
    case "ORIGINATOR":
      return {
        ...state,
        originator: state.disable ? state.originator : action.payload,
      };
    case "RECIPIENT":
      return {
        ...state,
        recipient: state.disable ? state.recipient : action.payload,
      };
    case "DEPARTMENT":
      return {
        ...state,
        department: state.disable ? state.department : action.payload,
      };
    case "COMPANY":
      return {
        ...state,
        company: state.disable ? state.company : action.payload,
      };

    case "CAUSE":
      return { ...state, cause: state.disable ? state.cause : action.payload };
    case "ROOT":
      return { ...state, root: state.disable ? state.root : action.payload };
    case "POSSIBLE_CAUSE":
      const { index1, value1 } = action.payload;
      const updatedPossibleCauses = [...state.possible_cause];
      updatedPossibleCauses[index1] = value1;
      return {
        ...state,
        possible_cause: state.disable
          ? state.possible_cause
          : updatedPossibleCauses,
      };
    case "PERCENTAGE":
      const { index2, value2 } = action.payload;
      const updatedPercentage = [...state.percentage];
      updatedPercentage[index2] = value2;
      return {
        ...state,
        percentage: state.disable ? state.percentage : updatedPercentage,
      };
    case "CONTAIN_PROBLEM":
      return {
        ...state,
        contain_problem: state.disable ? state.contain_problem : action.payload,
      };
    case "EFFECTIVENESS":
      return {
        ...state,
        effectiveness: state.disable ? state.effectiveness : action.payload,
      };
    case "REFERENCE":
      return {
        ...state,
        reference: state.disable ? state.reference : action.payload,
      };
    case "COMPLETE_DATE":
      return {
        ...state,
        complete_date: state.disable ? state.complete_date : action.payload,
      };
    case "FURTHER_ACTION":
      return {
        ...state,
        further_action: state.disable ? state.further_action : action.payload,
      };
    case "ACCEPTED_NAME":
      return {
        ...state,
        accepted_name: state.disable ? state.accepted_name : action.payload,
      };
    case "ACCEPTED_DATE":
      return {
        ...state,
        accepted_date: state.disable ? state.accepted_date : action.payload,
      };
    case "SIGN":
      return { ...state, sign: state.disable ? state.sign : action.payload };

    case "PREVENTIVE_PROBLEM":
      return {
        ...state,
        preventive_problem: state.disable
          ? state.preventive_problem
          : action.payload,
      };
    case "EFFECTIVENESS2":
      return {
        ...state,
        effectiveness2: state.disable ? state.effectiveness2 : action.payload,
      };
    case "REFERENCE2":
      return {
        ...state,
        reference2: state.disable ? state.reference2 : action.payload,
      };
    case "COMPLETE_DATE2":
      return {
        ...state,
        complete_date2: state.disable ? state.complete_date2 : action.payload,
      };
    case "FURTHER_ACTION2":
      return {
        ...state,
        further_action2: state.disable ? state.further_action2 : action.payload,
      };
    case "ACCEPTED_NAME2":
      return {
        ...state,
        accepted_name2: state.disable ? state.accepted_name2 : action.payload,
      };
    case "ACCEPTED_DATE2":
      return {
        ...state,
        accepted_date2: state.disable ? state.accepted_date2 : action.payload,
      };
    case "SIGN2":
      return { ...state, sign2: state.disable ? state.sign2 : action.payload };

    case "ITEM":
      return {};
    case "HAZARD":
      const { index3, value3 } = action.payload;
      const updatedHazard = [...state.hazard];
      updatedHazard[index3] = value3;
      return { ...state, hazard: state.disable ? state.hazard : updatedHazard };
    case "RISK":
      const { index4, value4 } = action.payload;
      const updatedRisk = [...state.risk];
      updatedRisk[index4] = value4;
      return { ...state, risk: state.disable ? state.risk : updatedRisk };
    case "RISK_CONTROL":
      const { index5, value5 } = action.payload;
      const updatedRiskControl = [...state.risk_control];
      updatedRiskControl[index5] = value5;
      return {
        ...state,
        risk_control: state.disable ? state.risk_control : updatedRiskControl,
      };

    case "ITEM2":
      return {};
    case "LIKELIHOOD":
      const { index6, value6, rating1, level1 } = action.payload;
      if (state.disable) return state;
      return {
        ...state,
        likelihood: state.likelihood?.map((item, index) =>
          index === index6 ? value6 : item
        ),
        risk_rating: state.risk_rating?.map((item, index) =>
          index === index6 ? rating1 : item
        ),
        risk_level: state.risk_level?.map((item, index) =>
          index === index6 ? level1 : item
        ),
      };
    case "CONSEQUENCE":
      const { index7, value7, rating2, level2 } = action.payload;
      if (state.disable) return state;
      return {
        ...state,
        consequence: state.consequence?.map((item, index) =>
          index === index7 ? value7 : item
        ),
        risk_rating: state.risk_rating?.map((item, index) =>
          index === index7 ? rating2 : item
        ),
        risk_level: state.risk_level?.map((item, index) =>
          index === index7 ? level2 : item
        ),
      };

    case "ACCESSED_BY":
      return {
        ...state,
        accessed_by: state.disable ? state.accessed_by : action.payload,
      };
    case "ACCESSED_DATE":
      return {
        ...state,
        accessed_date: state.disable ? state.accessed_date : action.payload,
      };

    case "RESET":
      return {
        ...initialState,
        ncr: sessionStorage.getItem("ncr") !== null ? sessionStorage.getItem("ncr") : initialState.ncr,
      };
    case "SET":
      return action.payload;
    case "COURSE_OF_ACTION":
      return { ...state, course_of_action: action.payload };
    case "DISABLE":
      return { ...state, disable: action.payload };
    case "EDITBUTTON":
      return { ...state, editbutton: action.payload };
    case "RESTORE":
      return { ...state, restore: action.payload };
    default:
      return state;
  }
};

// Create a provider component to wrap your app with the context
const AppProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      {children}
    </AppContext.Provider>
  );
};

// Create a custom hook to access the global state
const useAppState = () => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error("useAppState must be used within an AppProvider");
  }
  return context;
};

export { AppProvider, useAppState };
