import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { useAppState } from "../AppContext";
import { useNavigate } from "react-router-dom";
import { SearchNcr } from "../Fetcher/searchNcr";
import { ToastContainer, toast } from "react-toastify";
import {
  Spinner,
  Button,
  Dialog,
  DialogHeader,
  DialogFooter,
  Tooltip
} from "@material-tailwind/react";

const History = () => {
  const { state, dispatch } = useAppState();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [deleteNcr, setDeleteNcr] = useState(null);
  const [data, setData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentData, setCurrentData] = useState(null);

  useEffect(() => {
    fetchNCRList();
  }, []);

  useEffect(() => {
    const itemsPerPage = 15;
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const temp = data?.slice(indexOfFirstItem, indexOfLastItem);
    setCurrentData(temp);
  }, [currentPage, data]);

  const fetchNCRList = async () => {
    try {
      const res = await fetch(`https://backendncr.ampectech.com/api/ncr/all`);
      if (!res.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await res.json();
      setData(data.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected + 1);
  };

  const handleView = async (ncr) => {
    const res = await SearchNcr(ncr);
    if (res) {
      dispatch({ type: "SET", payload: res });
      dispatch({ type: "DISABLE", payload: true });
      dispatch({ type: "EDITBUTTON", payload: true });
      navigate("/");
    }
  };

  const handleDelete = async (ncr_no) => {
    const res = await fetch(
      `https://backendncr.ampectech.com/api/ncr/${ncr_no}`,
      {
        method: "DELETE",
      }
    );
    if (!res.ok) {
      throw new Error("Network response was not ok");
    } else {
      const data = await res.json();
      toast.success("NCR Deleted Successfully");
      dispatch({ type: "RESET" });
      fetchNCRList();
    }
    setOpen(!open);
  };

  return (
    <div className="mt-16 container mx-auto px-10">
      <p className="font-bold text-center py-6 text-4xl">HISTORY</p>
      <div className="text-center text-sm">
        <div className="flex justify-between mb-2 bg-black text-white font-semibold text-center ">
          <div className="flex-1 p-1 py-2">Serial</div>
          <div className="flex-1 p-1 py-2">NCR Number</div>
          <div className="flex-1 p-1 py-2">Created</div>
          <div className="flex-1 p-1 py-2">Action</div>
        </div>
        {currentData &&
          currentData.map((item, index) => {
            const key = Object.keys(item)[0];
            const currentItem = item[key];

            return (
              <div
                key={currentItem.ncr}
                className="flex justify-between border mb-1 bg-white shadow items-center  py-0.5 hover:bg-gray-100 hover:border-blue-400 duration-150"
              >
                <div className="flex-1 p-1">
                  {index + 1 + (currentPage - 1) * 15}
                </div>
                <div className="flex-1 p-1">{`${currentItem.ncr}`}</div>
                <div className="flex-1 p-1">
                  {currentItem.created_at.split(" ")[0]}
                </div>
                <div className="flex-1">
                  {/* View Button */}
                  <Tooltip content="View" className="bg-gray-200 text-black">
                  <button
                    className=" hover:text-black text-black/70 p-1 hover:bg-black/10 rounded-full"
                    onClick={() => handleView(currentItem.ncr.split("-")[1])}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="currentColor"
                        d="M12 9a3 3 0 0 0-3 3a3 3 0 0 0 3 3a3 3 0 0 0 3-3a3 3 0 0 0-3-3m0 8a5 5 0 0 1-5-5a5 5 0 0 1 5-5a5 5 0 0 1 5 5a5 5 0 0 1-5 5m0-12.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5Z"
                      />
                    </svg>
                  </button>
                  </Tooltip>
                  <Tooltip content="Delete" className="bg-gray-200 text-black">
                  <button
                    className="text-red-300 hover:text-red-600 p-1 hover:bg-black/10 rounded-full"
                    onClick={() => {
                      setOpen(!open);
                      setDeleteNcr(currentItem.ncr.split("-")[1]);
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="currentColor"
                        d="M7 21q-.825 0-1.413-.588T5 19V6H4V4h5V3h6v1h5v2h-1v13q0 .825-.588 1.413T17 21H7Zm2-4h2V8H9v9Zm4 0h2V8h-2v9Z"
                      />
                    </svg>
                  </button>
                  </Tooltip>
                </div>
              </div>
            );
          })}
      </div>
      <Dialog open={open} handler={() => setOpen(!open)}>
        <DialogHeader>Are you sure you want to delete?</DialogHeader>
        <DialogFooter>
          <Button
            variant="text"
            color="red"
            onClick={() => setOpen(!open)}
            className="mr-1"
          >
            <span>Cancel</span>
          </Button>
          <Button
            variant="gradient"
            color="green"
            onClick={() => handleDelete(deleteNcr)}
          >
            <span>Confirm</span>
          </Button>
        </DialogFooter>
      </Dialog>
      <div
        className={`flex justify-center my-10 ${
          data?.length > 15 ? "" : "hidden"
        }`}
      >
        <ReactPaginate
          breakLabel={"..."}
          pageCount={Math.ceil((data?.length || 0) / 15)}
          pageRangeDisplayed={3}
          onPageChange={handlePageClick}
          previousLabel="Previous"
          nextLabel="Next"
          previousLinkClassName="px-2 py-px rounded-sm bg-blue-500 text-white"
          nextLinkClassName="px-2 py-px rounded-sm bg-blue-500 text-white"
          activeClassName="bg-blue-500 text-white"
          pageLinkClassName="p-2"
          className="flex gap-2"
        />
      </div>
      <ToastContainer />
    </div>
  );
};

export default History;
