import React from "react";
import { Textarea, Input } from "@material-tailwind/react";
import CheckBox from "./CheckBox";
import { useAppState } from "../AppContext";

const PreventiveAction = () => {
  const { state, dispatch } = useAppState();

  return (
    <div className="mt-6 w-full space-y-2">
      <p className="italic font-semibold  text-base">
        Preventive Actions to STOP the Problem happening again
      </p>
      <div className="flex gap-5">
        <Textarea
          value={
            state.preventive_problem === "null"
              ? ""
              : state.preventive_problem || ""
          }
          onChange={(e) =>
            dispatch({ type: "PREVENTIVE_PROBLEM", payload: e.target.value })
          }
          color="pink"
          label="Preventive Action"
          labelProps={{
            className: `${
              state.preventive_problem
                ? ""
                : "print:peer-placeholder-shown:text-gray-100/40"
            }`,
          }}
          containerProps={{
            className: "flex-1 h-60",
          }}
        />
        <div className="space-y-2 w-[230px]">
          <p className="font-semibold">Effectiveness (QA use only)</p>
          {!state.disable ||
          (state.disable && Number(state.effectiveness2) === 1) ? (
            <div className="flex gap-2">
              <CheckBox
                change={() => dispatch({ type: "EFFECTIVENESS2", payload: 1 })}
                check={state.effectiveness2 === 1}
              />
              <p>Performance Improved</p>
            </div>
          ) : null}
          {!state.disable ||
          (state.disable && Number(state.effectiveness2) === 2) ? (
            <div className="flex gap-2">
              <CheckBox
                change={() => dispatch({ type: "EFFECTIVENESS2", payload: 2 })}
                check={state.effectiveness2 === 2}
              />
              <p>Design Improved</p>
            </div>
          ) : null}
          {!state.disable ||
          (state.disable && Number(state.effectiveness2) === 3) ? (
            <div className="flex gap-2">
              <CheckBox
                change={() => dispatch({ type: "EFFECTIVENESS2", payload: 3 })}
                check={state.effectiveness2 === 3}
              />
              <p>Objective Evidence Gathered</p>
            </div>
          ) : null}
          {!state.disable ||
          (state.disable && Number(state.effectiveness2) === 3) ? (
            <div className="flex gap-2 pb-2">
              <CheckBox
                change={() => dispatch({ type: "EFFECTIVENESS2", payload: 4 })}
                check={state.effectiveness2 === 4}
              />
              <p>Document update/added</p>
            </div>
          ) : null}
          <Textarea
            value={state.reference2 === "null" ? "" : state.reference2 || ""}
            onChange={(e) =>
              dispatch({ type: "REFERENCE2", payload: e.target.value })
            }
            color="pink"
            label="Reference"
            labelProps={{
              className: `${
                state.reference2
                  ? ""
                  : "print:peer-placeholder-shown:text-gray-100/40"
              }`,
            }}
          />
        </div>
      </div>
      <div className="flex gap-5 items-center pt-2">
        <p>Target to Complete</p>
        <Input
          value={state.complete_date2 || ""}
          onChange={(e) =>
            dispatch({ type: "COMPLETE_DATE2", payload: e.target.value })
          }
          type="date"
          color="pink"
          label="Date"
          className={`${
            state.complete_date2 ? "" : "print:text-gray-100/40 text-gray-400"
          }`}
          containerProps={{
            className: "flex-1",
          }}
        />
        <div className="flex gap-2 w-[230px]">
          <CheckBox
            change={() =>
              dispatch({
                type: "FURTHER_ACTION2",
                payload: state.further_action2 === "yes" ? "no" : "yes",
              })
            }
            check={state.further_action2 === "yes"}
          />
          <p>Further Action Needed</p>
        </div>
      </div>
      <div className="flex gap-5 pt-2">
        <Input
          value={
            state.accepted_name2 === "null" ? "" : state.accepted_name2 || ""
          }
          onChange={(e) =>
            dispatch({ type: "ACCEPTED_NAME2", payload: e.target.value })
          }
          color="pink"
          label="Accepted By (Name)"
          labelProps={{
            className: `${
              state.accepted_name2
                ? ""
                : "print:peer-placeholder-shown:text-gray-100/40"
            }`,
          }}
          containerProps={{
            className: "flex-1",
          }}
        />
        <Input
          value={state.accepted_date2 || ""}
          onChange={(e) =>
            dispatch({ type: "ACCEPTED_DATE2", payload: e.target.value })
          }
          color="pink"
          label="Date"
          type="date"
          className={`${
            state.accepted_date2 ? "" : "print:text-gray-100/40 text-gray-400"
          }`}
          containerProps={{
            className: "flex-1",
          }}
        />
        <Input
          value={state.sign2 === "null" ? "" : state.sign2 || ""}
          onChange={(e) => dispatch({ type: "SIGN2", payload: e.target.value })}
          color="pink"
          label="Sign"
          labelProps={{
            className: `${
              state.sign2 ? "" : "print:peer-placeholder-shown:text-gray-100/40"
            }`,
          }}
          containerProps={{
            className: "w-[230px]",
          }}
        />
      </div>
    </div>
  );
};

export default PreventiveAction;
