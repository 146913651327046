import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
    Card,
    Input,
    Button,
    Typography,
  } from "@material-tailwind/react";
  
  const Login = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const handleLogin = async () => {
      const formData = new FormData();
      formData.append("email", email);
      formData.append("password", password);

      const res = await fetch("https://backendncr.ampectech.com/api/login", {
        method: "POST",
        body: formData,
      });
      
      const data = await res.json();
      
      if (data.status === "success") {
        localStorage.setItem("token", data.authorization.token);
        sessionStorage.setItem("user", JSON.stringify(data.user));
        sessionStorage.setItem("role", data.user.user_type);
        navigate("/");
      } else {
        alert(data.message);
      }
    }

    return (
      <div className="w-full flex min-h-screen items-center justify-center">
        <Card color="transparent" shadow={false}>
          <Typography variant="h4" color="blue-gray" className="text-center pb-4">
            Sign In
          </Typography>
          <form className="mt-8 mb-2 w-80 max-w-screen-lg sm:w-96">
            <div className="mb-4 flex flex-col gap-6">
              <Input size="lg" label="Email" onChange={e => setEmail(e.target.value)} value={email} />
              <Input value={password} type="password" size="lg" label="Password" onChange={e => setPassword(e.target.value)} onKeyDown={e => {if(e.key === "Enter") handleLogin()}} />
            </div>  
            <Button className="mt-6" fullWidth onClick={handleLogin} >
              Sign In
            </Button>
          </form>
        </Card>
      </div>
    );
  };
  
  export default Login;
  