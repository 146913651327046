import React from "react";
import { Input } from "@material-tailwind/react";
import { useAppState } from "../AppContext";

const RiskAssesment = () => {
  const { state, dispatch } = useAppState();
  const likelihoodMap = {
    Rare: 1,
    Unlikely: 2,
    Moderate: 3,
    Likely: 4,
    "Almost Certain": 5,
  };

  const likelihoodKeys = Object.keys(likelihoodMap);

  const consequenceMap = {
    Insignificant: 6,
    Minor: 11,
    Moderate: 14,
    Major: 23,
    Catastrophic: 31,
  };

  const consequenceKeys = Object.keys(consequenceMap);

  const riskLevel = (riskRating) => {
    if (riskRating < 23) {
      return "Low Risk";
    } else if (riskRating < 42) {
      return "Moderate Risk";
    } else if (riskRating < 70) {
      return "Significant Risk";
    } else {
      return "High Risk";
    }
  };

  return (
    <div className="mt-6 w-full space-y-2">
      <p className="italic font-semibold  text-base">Risk Assesment</p>
      <p>Procedure: (refer risk table folder)</p>
      <p>
        1. Conduct Risk Assessment to check if the Risk Rating is well below
        Moderate Risk level.
      </p>
      <p>
        2. If the risk rating is <span className="text-red-400">ABOVE</span>{" "}
        Moderate Risk level, change the Risk Control.
      </p>
      <p className="pb-2">3. Assess by at least two subject matter experts.</p>

      <table className="w-full border-collapse border border-gray-400">
        <thead>
          <tr>
            <th className="border w-1/6 border-gray-400 p-2">Item</th>
            <th className="border flex-1 border-gray-400 p-2">Hazard</th>
            <th className="border flex-1 border-gray-400 p-2">Risk</th>
            <th className="border flex-1 border-gray-400 p-2">Risk Control</th>
          </tr>
        </thead>
        <tbody>
          {state.item?.map((it, index) => (
            <tr key={index}>
              <td className="border border-gray-400 py-1 px-2 text-center">
                {it}
              </td>
              <td className="border border-gray-400">
                <textarea
                  type="text"
                  value={state.hazard[index] || ""}
                  onChange={(e) =>
                    dispatch({
                      type: "HAZARD",
                      payload: { index3: index, value3: e.target.value },
                    })
                  }
                  className="w-full rounded py-1 px-2 h-20"
                />
              </td>
              <td className="border border-gray-400">
                <textarea
                  type="text"
                  value={state.risk[index] || ""}
                  onChange={(e) =>
                    dispatch({
                      type: "RISK",
                      payload: { index4: index, value4: e.target.value },
                    })
                  }
                  className="w-full rounded py-1 px-2 h-20"
                />
              </td>
              <td className="border border-gray-400">
                <textarea
                  type="text"
                  value={state.risk_control[index] || ""}
                  onChange={(e) =>
                    dispatch({
                      type: "RISK_CONTROL",
                      payload: { index5: index, value5: e.target.value },
                    })
                  }
                  className="w-full rounded py-1 px-2 h-20"
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <p className="text-center font-semibold">Risk Assesment</p>

      <table className="w-full border-collapse border border-gray-400">
        <thead>
          <tr>
            <th className="border w-1/6 border-gray-400 p-2">Item</th>
            <th className="border w-[20.75%] border-gray-400 p-2">
              Likelihood
            </th>
            <th className="border w-[20.75%] border-gray-400 p-2">
              Consequence
            </th>
            <th className="border w-[20.75%] border-gray-400 p-2">
              Risk Rating
            </th>
            <th className="border w-[20.75%] border-gray-400 p-2">
              Risk Level
            </th>
          </tr>
        </thead>
        <tbody>
          {state.item2 &&
            state.item2?.map((it, index) => (
              <tr key={index}>
                <td className="border w-1/6 border-gray-400 py-1 px-2 text-center">
                  {it}
                </td>
                <td className="border flex-1 border-gray-400">
                  <select
                    className="w-full py-1 px-2"
                    value={state.likelihood[index] || ""}
                    onChange={(e) =>
                      dispatch({
                        type: "LIKELIHOOD",
                        payload: {
                          index6: index,
                          value6: e.target.value,
                          rating1:
                            likelihoodMap[e.target.value] *
                            consequenceMap[state.consequence[index]],
                          level1: riskLevel(
                            likelihoodMap[e.target.value] *
                              consequenceMap[state.consequence[index]]
                          ),
                        },
                      })
                    }
                  >
                    {likelihoodKeys?.map((key, index) => (
                      <option key={index} value={key}>
                        {key}
                      </option>
                    ))}
                  </select>
                </td>
                <td className="border flex-1 border-gray-400">
                  <select
                    className="w-full rounded py-1 px-2"
                    value={state.consequence[index] || ""}
                    onChange={(e) =>
                      dispatch({
                        type: "CONSEQUENCE",
                        payload: {
                          index7: index,
                          value7: e.target.value,
                          rating2:
                            likelihoodMap[state.likelihood[index]] *
                            consequenceMap[e.target.value],
                          level2: riskLevel(
                            likelihoodMap[state.likelihood[index]] *
                              consequenceMap[e.target.value]
                          ),
                        },
                      })
                    }
                  >
                    {consequenceKeys?.map((key, index) => (
                      <option key={index} value={key}>
                        {key}
                      </option>
                    ))}
                  </select>
                </td>
                <td className="border flex-1 border-gray-400">
                  <p className="py-1 px-2 text-center">
                    {state.risk_rating[index]}
                  </p>
                </td>
                <td className="border flex-1 border-gray-400">
                  <p className="py-1 px-2">{state.risk_level[index]}</p>
                </td>
              </tr>
            ))}
        </tbody>
      </table>

      <div className="flex gap-5 pt-2">
        <Input
          value={state.accessed_by === 'null' ? "" : state.accessed_by || ""}
          onChange={(e) =>
            dispatch({ type: "ACCESSED_BY", payload: e.target.value })
          }
          color="pink"
          label="Assessed By (Name)"
          labelProps={
            {
              className: `${state.accessed_by ? '' : 'print:peer-placeholder-shown:text-gray-100/40'}`,
            }
          }
          containerProps={{
            className: "flex-1",
          }}
        />
        <Input
          value={state.accessed_date || ""}
          onChange={(e) =>
            dispatch({ type: "ACCESSED_DATE", payload: e.target.value })
          }
          color="pink"
          label="Date"
          type="date"
          className={`${state.accessed_date ? '' : 'print:text-gray-100/40 text-gray-400'}`}
          containerProps={{
            className: "flex-1",
          }}
        />
      </div>
    </div>
  );
};

export default RiskAssesment;
