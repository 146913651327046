import React from "react";
import { Input } from "@material-tailwind/react";
import CheckBox from "./CheckBox";
import { useAppState } from "../AppContext";

const Problem = () => {
  const { state, dispatch } = useAppState();

  const handleFileChange = (event) => {
    const files = event.target.files;
    console.log(state)
    dispatch({ type: "FILES", payload: [...state.files, ...files] });
  };

  return (
    <div className="mt-6 w-full space-y-2">
      <p className="italic font-semibold  text-base">Describe the Problem</p>
      <textarea
        value={state.description === 'null' ? "" : state.description || ""}
        placeholder="Description"
        className="text-blue-gray-700 border w-full rounded-md border-gray-400 p-2 focus:outline-pink-500 font-sans h-40 print:placeholder:invisible"
        onChange={(e) =>
          dispatch({ type: "DESCRIPTION", payload: e.target.value })
        }
      />
      <div className="flex gap-5">
        <div className="w-2/3">
          <textarea
            value={state.effect === 'null' ? "" : state.effect || ""}
            onChange={(e) =>
              dispatch({ type: "EFFECT", payload: e.target.value })
            }
            className="text-blue-gray-700 w-full border rounded-md border-gray-400 p-2 focus:outline-pink-500 font-sans print:placeholder:text-gray-100/40 h-24"
            placeholder="Effect"
          />
        </div>
        <div className="w-1/3 h-24 flex items-center justify-center bg-gray-100 rounded-md border border-gray-400 border-dashed">
          <input
            disabled={state.disable}
            type="file"
            hidden
            id="upload"
            multiple
            accept=".png,.pdf,.xlx,.csv"
            onChange={handleFileChange}
          />
          <label htmlFor="upload">
            <svg
              className="m-auto"
              xmlns="http://www.w3.org/2000/svg"
              width="64"
              height="60"
              viewBox="0 0 24 24"
            >
              <path
                fill="black"
                d="M19.35 10.04A7.49 7.49 0 0 0 12 4C9.11 4 6.6 5.64 5.35 8.04A5.994 5.994 0 0 0 0 14c0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5c0-2.64-2.05-4.78-4.65-4.96zM14 13v4h-4v-4H7l5-5l5 5h-3z"
              />
            </svg>
            <div>
              {state.files?.length ? (
                <div className="flex gap-5">
                  <p>{state.files.length + " files selected"}</p>
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      dispatch({ type: "FILES", payload: [] });
                    }}
                    className="bg-red-400 text-white rounded px-3 py-px"
                  >
                    Clear
                  </button>
                </div>
              ) : (<div className="text-center leading-3">
                Click To Upload <br /> pdf, xlsx, csv, png, jpeg, jpg
              </div>
              )}
            </div>
          </label>
        </div>
      </div>

      <p>Nature of the Problem:</p>
      <hr />
      <div className="flex gap-5">
        <div className="w-1/3 flex gap-2">
          <CheckBox
            change={() => dispatch({ type: "NATURE_OF_PROBLEM", payload: 1 })}
            check={state.nature_of_problem?.includes(1)}
          />
          <p>Customer Complaint</p>
        </div>
        <div className="w-1/3 flex gap-2">
          <CheckBox
            change={() => dispatch({ type: "NATURE_OF_PROBLEM", payload: 2 })}
            check={state.nature_of_problem?.includes(2)}
          />
          <p>Design Problem</p>
        </div>
        <div className="w-1/3 flex gap-2">
          <CheckBox
            change={() => dispatch({ type: "NATURE_OF_PROBLEM", payload: 3 })}
            check={state.nature_of_problem?.includes(3)}
          />
          <p>Material Problem</p>
        </div>
      </div>
      <div className="flex gap-5">
        <div className="w-1/3 flex gap-2">
          <CheckBox
            change={() => dispatch({ type: "NATURE_OF_PROBLEM", payload: 4 })}
            check={state.nature_of_problem?.includes(4)}
          />
          <p>Equipment Problem</p>
        </div>
        <div className="w-1/3 flex gap-2">
          <CheckBox
            change={() => dispatch({ type: "NATURE_OF_PROBLEM", payload: 5 })}
            check={state.nature_of_problem?.includes(5)}
          />
          <p>Workmanship Problem</p>
        </div>
        <div className="w-1/3 flex gap-2">
          <CheckBox
            change={() => dispatch({ type: "NATURE_OF_PROBLEM", payload: 6 })}
            check={state.nature_of_problem?.includes(6)}
          />
          <p>Process Error</p>
        </div>
      </div>
      <div className="flex gap-5">
        <div className="w-1/3 flex gap-2">
          <CheckBox
            change={() => dispatch({ type: "NATURE_OF_PROBLEM", payload: 7 })}
            check={state.nature_of_problem?.includes(7)}
          />
          <p>First Article Error</p>
        </div>
        <div className="w-1/3 flex gap-2">
          <CheckBox
            change={() => dispatch({ type: "NATURE_OF_PROBLEM", payload: 8 })}
            check={state.nature_of_problem?.includes(8)}
          />
          <p>Instruction not clear</p>
        </div>
        <div className="w-1/3 flex gap-2">
          <CheckBox
            change={() => dispatch({ type: "NATURE_OF_PROBLEM", payload: 9 })}
            check={state.nature_of_problem?.includes(9)}
          />
          <p>Instruction not follwoed</p>
        </div>
      </div>
      <div className="flex gap-5">
        <div className="w-1/3 flex gap-2">
          <CheckBox
            change={() => dispatch({ type: "NATURE_OF_PROBLEM", payload: 10 })}
            check={state.nature_of_problem?.includes(10)}
          />
          <p>Quality Audit</p>
        </div>
        <div className="w-1/3 flex gap-2">
          <input
            value={state.other2 === 'null' ? "" : state.other2 || ""}
            onChange={(e) =>
              dispatch({ type: "OTHER2", payload: e.target.value })
            }
            placeholder="Other"
            type="text"
            className="w-full border rounded border-gray-600 px-1"
          />
        </div>
        <div className="w-1/3 flex gap-2">
          <CheckBox
            change={() => dispatch({ type: "NATURE_OF_PROBLEM", payload: 12 })}
            check={state.nature_of_problem?.includes(12)}
          />
          <p>Cost of Quality</p>
        </div>
      </div>
        <div className="flex gap-5">
          <div className="flex-1 flex gap-2">
            <Input
              value={Number(state.administration_cost) || ""}
              onChange={(e) =>
                dispatch({
                  type: "ADMINISTRATION_COST",
                  payload: e.target.value,
                })
              }
              color="pink"
              type="number"
              label="Adminstration Cost"
              labelProps={{
                className: `${
                  state.administration_cost
                    ? ""
                    : "print:peer-placeholder-shown:text-gray-100/40"
                }`,
              }}
            />
          </div>
          <div className="flex-1 flex gap-2">
            <Input
              value={Number(state.material_cost) || ""}
              onChange={(e) =>
                dispatch({ type: "MATERIAL_COST", payload: e.target.value })
              }
              color="pink"
              label="Material Cost"
              type="number"
              labelProps={{
                className: `${
                  state.material_cost
                    ? ""
                    : "print:peer-placeholder-shown:text-gray-100/40"
                }`,
              }}
            />
          </div>
          <div className="flex-1 flex gap-2">
            <Input
              value={Number(state.labour_cost) || ""}
              onChange={(e) =>
                dispatch({ type: "LABOUR_COST", payload: e.target.value })
              }
              color="pink"
              type="number"
              label="Labour Cost"
              labelProps={{
                className: `${
                  state.labour_cost
                    ? ""
                    : "print:peer-placeholder-shown:text-gray-100/40"
                }`,
              }}
            />
          </div>
        </div>
      {/* )} */}
      <div className="flex gap-5">
        <div className="flex-1">
          <Input
            value={state.originator ==='null' ? "" : state.originator || ""}
            onChange={(e) =>
              dispatch({ type: "ORIGINATOR", payload: e.target.value })
            }
            color="pink"
            label="Orignator"
            labelProps={{
              className: `${
                state.originator
                  ? ""
                  : "print:peer-placeholder-shown:text-gray-100/40"
              }`,
            }}
          />
        </div>
        <div className="flex-1">
          <Input
            value={state.recipient ==='null' ? "" : state.recipient || ""}
            onChange={(e) =>
              dispatch({ type: "RECIPIENT", payload: e.target.value })
            }
            color="pink"
            label="Receiptent"
            labelProps={{
              className: `${
                state.recipient
                  ? ""
                  : "print:peer-placeholder-shown:text-gray-100/40"
              }`,
            }}
          />
        </div>
      </div>
      <div className="flex gap-5 pt-2">
        <div className="flex-1">
          <Input
            value={state.department ==='null' ? "" : state.department || ""}
            onChange={(e) =>
              dispatch({ type: "DEPARTMENT", payload: e.target.value })
            }
            color="pink"
            label="Department"
            labelProps={{
              className: `${
                state.department
                  ? ""
                  : "print:peer-placeholder-shown:text-gray-100/40"
              }`,
            }}
          />
        </div>
        <div className="flex-1">
          <Input
            value={state.company ==='null' ? "" : state.company || ""}
            onChange={(e) =>
              dispatch({ type: "COMPANY", payload: e.target.value })
            }
            color="pink"
            label="Department / Company"
            labelProps={{
              className: `${
                state.company
                  ? ""
                  : "print:peer-placeholder-shown:text-gray-100/40"
              }`,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Problem;
