export async function FetchData(state) {
  try {
    const formData = new FormData();
    
    formData.append("from", state.from);
    formData.append("reason", state.reason);
    formData.append("other", state.other);
    formData.append("name", state.name);
    formData.append("area", state.area);
    formData.append("issue_date", state.issue_date);
    formData.append("subject", state.subject);
    formData.append("response_required", state.response_required);
    formData.append("product", state.product);
    formData.append("quantity", state.quantity);
    formData.append("response_date", state.response_date);
    formData.append("part_number", state.part_number);
    formData.append("stock_affected", state.stock_affected);

    formData.append("description", state.description);
    state.files?.forEach((file, index) => {
      formData.append(`files[${index}]`, file);
    });
    formData.append("effect", state.effect);
    formData.append("nature_of_problem", JSON.stringify(state.nature_of_problem));
    formData.append("other2", state.other2);
    formData.append("administration_cost", state.administration_cost);
    formData.append("material_cost", state.material_cost);
    formData.append("labour_cost", state.labour_cost);
    formData.append("originator", state.originator);
    formData.append("recipient", state.recipient);
    formData.append("department", state.department);
    formData.append("company", state.company);

    formData.append("ncr", state.ncr);
    formData.append("cause", state.cause);
    formData.append("root", JSON.stringify(state.root));
    formData.append("possible_cause", JSON.stringify(state.possible_cause));
    formData.append("percentage", JSON.stringify(state.percentage));
    formData.append("course_of_action", state.course_of_action);

    formData.append("contain_problem", state.contain_problem);
    formData.append("effectiveness", state.effectiveness);
    formData.append("reference", state.reference);
    formData.append("complete_date", state.complete_date);
    formData.append("further_action", state.further_action);
    formData.append("accepted_name", state.accepted_name);
    formData.append("accepted_date", state.accepted_date);
    formData.append("sign", state.sign);

    formData.append("preventive_problem", state.preventive_problem);
    formData.append("effectiveness2", state.effectiveness2);
    formData.append("reference2", state.reference2);
    formData.append("complete_date2", state.complete_date2);
    formData.append("further_action2", state.further_action2);
    formData.append("accepted_name2", state.accepted_name2);
    formData.append("accepted_date2", state.accepted_date2);
    formData.append("sign2", state.sign2);

    formData.append("item", JSON.stringify(state.item));
    formData.append("hazard", JSON.stringify(state.hazard));
    formData.append("risk", JSON.stringify(state.risk));
    formData.append("risk_control", JSON.stringify(state.risk_control));

    formData.append("item2", JSON.stringify(state.item2));
    formData.append("likelihood", JSON.stringify(state.likelihood));
    formData.append("consequence", JSON.stringify(state.consequence));
    formData.append("risk_rating", JSON.stringify(state.risk_rating));
    formData.append("risk_level", JSON.stringify(state.risk_level));
    formData.append("accessed_by", state.accessed_by);
    formData.append("accessed_date", state.accessed_date);
    formData.append(
      "type",
      state.from === 1 ? "C" : state.from === 2 ? "E" : "I"
    );

    const response = await fetch("https://backendncr.ampectech.com/api/save", {
      method: "POST",
      body: formData,
    });
    if (!response.ok) {
      throw new Error("Network response was not ok");
    } else {
      const data = await response.json();
      sessionStorage.removeItem("ncr");
      return data.message;
    }
  } catch (error) {
    console.error("Error fetching data:", error);
    return false;
    throw error;
  }
}
