import React from "react";

const CheckBox = ( {change, check} ) => {
  return (
    <input
      type="checkbox"
      className="accent-black"
      onChange={change}
      checked={check}  
    />
  );
};

export default CheckBox;
