import React, { useState } from "react";
import { Input, Button, Typography } from "@material-tailwind/react";
import { Link } from "react-router-dom";

const Profile = () => {
  const [currentPass, setCurrentPass] = useState("");
  const [newPass, setNewPass] = useState("");

  const handlePassChange = async () => {
    const formdata = new FormData();
    formdata.append("current_password", currentPass);
    formdata.append("new_password", newPass);
    const res = await fetch(
      "https://backendncr.ampectech.com/api/change-password",
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: formdata,
      }
    );

    const data = await res.json();

    if (data.status === "success") {
      alert("Password changed successfully");
    } else {
      alert("Password change failed");
    }
  };

  return (
    <div className="w-full min-h-screen flex items-center justify-center relative">
      <Link to='/' className="absolute top-20 left-10 text-gray-500 hover:text-black">/Home</Link>
      <div>
      <Typography variant="h4" color="blue-gray">
        Change Password
      </Typography>
      <form className="mt-8 mb-2 w-80 max-w-screen-lg sm:w-96">
        <div className="mb-1 flex flex-col gap-6">
          <Typography
            variant="h6"
            color="blue-gray"
            className="-mb-3"
          >
            Current Password
          </Typography>
          <Input
            size="lg"
            placeholder="********"
            className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
            labelProps={{
              className: "before:content-none after:content-none",
            }}
            onChange={(e) => setCurrentPass(e.target.value)}
            type="password"
          />
          <Typography
            variant="h6"
            color="blue-gray"
            className="-mb-3"
          >
            New Password
          </Typography>
          <Input
            size="lg"
            placeholder="********"
            className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
            labelProps={{
              className: "before:content-none after:content-none",
            }}
            onChange={(e) => setNewPass(e.target.value)}
            type="password"
            onKeyDown={(e) => {
              if (e.key === "Enter") handlePassChange();
            }}
          />
        </div>
        <Button className="mt-6" fullWidth onClick={handlePassChange}>
          Change Password
        </Button>
        <Typography color="gray" className="mt-4 text-center font-normal">
          Don't want to change password?{" "}
          <Link to="/" className="text-teal-400 font-semibold">
            Go Back
          </Link>
        </Typography>
      </form>
      </div>
    </div>
  );
};

export default Profile;
