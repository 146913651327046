import React, { useState, useEffect } from "react";
import Cause from "./components/Cause";
import CorrectiveAction from "./components/CorrectiveAction";
import Header from "./components/Header";
import PreventiveAction from "./components/PreventiveAction";
import Problem from "./components/Problem";
import RiskAssesment from "./components/RiskAssesment";
import RootCause from "./components/RootCause";
import { useAppState } from "./AppContext";
import { FetchData } from "./Fetcher/fetchNCR";
import { UpdateNCR } from "./Fetcher/updateNCR";
import Register from "./components/Register";
import Login from "./components/Login";
import Navbar from "./components/Navbar";
import Trash from "./components/Trash";
import Profile from "./components/Profile";
import { Routes, Route, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Spinner,
  Button,
  Dialog,
  DialogHeader,
  DialogFooter,
} from "@material-tailwind/react";
import History from "./components/History";

function App() {
  const [open, setOpen] = useState(false);
  const [openRestore, setOpenRestore] = useState(false);
  const { state, dispatch } = useAppState();
  const [isLoading, setIsLoading] = useState(true);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    checkLogin();
  }, []); // Empty dependency array to run the effect only once

  const fetchLogin = async () => {
    if (!localStorage.getItem("token")) {
      return false;
    }
    try {
      const res = await fetch("https://backendncr.ampectech.com/api/me", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      if (!res.ok) {
        sessionStorage.removeItem("user");
        sessionStorage.removeItem("role");
        throw new Error("Network response was not ok");
      }

      const data = await res.json();
      if (data?.status === "success") {
        sessionStorage.setItem("user", JSON.stringify(data.user));
        sessionStorage.setItem("role", data.user.user_type);
        return true;
      } else {
        return false;
      }
    } catch (error) {
      sessionStorage.removeItem("user");
      sessionStorage.removeItem("role");
      navigate("/login");
      console.error("Error fetching login data:", error);
    }
  };

  const checkLogin = async () => {
    const loggedIn = await fetchLogin();
    setIsLoading(false);
    if (!loggedIn) {
      sessionStorage.removeItem("user");
      sessionStorage.removeItem("role");
      navigate("/login");
    }
  };

  const handleDelete = async () => {
    const res = await fetch(
      `https://backendncr.ampectech.com/api/ncr/${state.ncr}`,
      {
        method: "DELETE",
      }
    );
    if (!res.ok) {
      setOpen(false);
      throw new Error("Network response was not ok");
    } else {
      const data = await res.json();
      setOpen(!open);
      toast.success("NCR Deleted Successfully");
      dispatch({ type: "RESET" });
    }
  };

  const openModal = (image) => {
    setSelectedImage(image);
    setModalVisible(true);
  };

  const closeModal = () => {
    setSelectedImage(null);
    setModalVisible(false);
  };

  if (isLoading) {
    return (
      <div className="h-screen w-screen flex items-center justify-center">
        <Spinner className="h-10 w-10" />
      </div>
    );
  }

  const handleRestore = async () => {
    try {
      const res = await fetch(
        `https://backendncr.ampectech.com/api/restore-ncr/${state.ncr}`,
        {
          method: "PUT",
        }
      );
      if (!res.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await res.json();
      toast.success(data.message);
      setOpenRestore(false);
      dispatch({ type: "EDITBUTTON", payload: true });
      dispatch({ type: "DISABLE", payload: true });
      dispatch({ type: "RESTORE", payload: false });
    } catch (error) {
      setOpenRestore(false);
      console.error("Error fetching data:", error);
    }
  };

  const permanentDelete = async () => {
    try {
      const res = await fetch(
        `https://backendncr.ampectech.com/api/permanently-delete-ncr/${state.ncr}`,
        {
          method: "DELETE",
        }
      );
      if (!res.ok) {
        setOpenRestore(false);
        throw new Error("Network response was not ok");
      }
      setOpenRestore(false);
      const data = await res.json();
      toast.success(data.message);
      dispatch({ type: "RESET" });
    } catch (error) {
      setOpenRestore(false);
      console.error("Error fetching data:", error);
    }
  };

  return (
    <Routes>
      <Route>
        <Route
          path="/profile"
          element={
            <>
              <Navbar />
              <Profile />
            </>
          }
        />
      </Route>
      <Route
        path="/"
        element={
          <div className="max-w-[800px] mx-auto p-10 text-sm">
            <Navbar />
            <Header />
            <Problem />
            <Cause />
            <RootCause />
            <CorrectiveAction />
            <PreventiveAction />
            <RiskAssesment />
            <div className="flex justify-center p-5 print:hidden">
              {!state.editbutton ||
              sessionStorage.getItem("role") !== "admin" ? (
                <button
                  onClick={async () => {
                    const result = await FetchData(state);
                    if (result) {
                      toast.success("NCR Submitted Successfully");
                      dispatch({ type: "RESET" });
                      window.print();
                    }
                  }}
                  className="bg-black hover:shadow-lg text-white py-2 px-20 rounded font-semibold hover:bg-white hover:text-black border border-black"
                >
                  Submit
                </button>
              ) : !state.restore ? (
                <div className="space-x-5">
                  <button
                    className="bg-black hover:shadow-lg text-white py-2 px-20 rounded font-semibold hover:bg-white hover:text-black border border-black"
                    onClick={() => {
                      dispatch({ type: "EDITBUTTON", payload: true });
                      dispatch({ type: "DISABLE", payload: false });
                      window.scrollTo({
                        top: 0,
                        behavior: "smooth",
                      });
                    }}
                  >
                    Edit
                  </button>
                  <button
                    onClick={async () => {
                      const result = await UpdateNCR(state);
                      if (result) {
                        toast.success("NCR Updated Successfully");
                        dispatch({ type: "DISABLE", payload: true });
                        window.print();
                      }
                    }}
                    className="bg-black hover:shadow-lg text-white py-2 px-20 rounded font-semibold hover:bg-white hover:text-black border border-black"
                  >
                    Update
                  </button>
                  <button
                    onClick={() => setOpen(!open)}
                    className="bg-black hover:shadow-lg text-white py-2 px-20 rounded font-semibold hover:bg-white hover:text-black border border-black"
                  >
                    Delete
                  </button>
                  <Dialog open={open} handler={() => setOpen(!open)}>
                    <DialogHeader>
                      Are you sure you want to delete?
                    </DialogHeader>
                    <DialogFooter>
                      <Button
                        variant="text"
                        color="red"
                        className="mr-1"
                        onClick={() => setOpen(!open)}
                      >
                        <span>Cancel</span>
                      </Button>
                      <Button
                        variant="gradient"
                        color="green"
                        onClick={handleDelete}
                      >
                        <span>Confirm</span>
                      </Button>
                    </DialogFooter>
                  </Dialog>
                </div>
              ) : (
                <div className="flex space-x-5">
                  <button
                    className="bg-black hover:shadow-lg text-white py-2 px-20 rounded font-semibold hover:bg-white hover:text-black border border-black"
                    onClick={handleRestore}
                  >
                    Restore
                  </button>
                  <button
                    onClick={() => setOpenRestore(!openRestore)}
                    className="bg-black hover:shadow-lg text-white py-2 px-8 rounded font-semibold hover:bg-white hover:text-black border border-black"
                  >
                    Delete Permanently
                  </button>
                  <Dialog
                    open={openRestore}
                    handler={() => setOpenRestore(!openRestore)}
                  >
                    <DialogHeader>
                      Are you sure you want to delete this NCR permanently?
                    </DialogHeader>
                    <DialogFooter>
                      <Button
                        variant="text"
                        color="red"
                        className="mr-1"
                        onClick={() => setOpenRestore(!openRestore)}
                      >
                        <span>Cancel</span>
                      </Button>
                      <Button
                        variant="gradient"
                        color="green"
                        onClick={permanentDelete}
                      >
                        <span>Confirm</span>
                      </Button>
                    </DialogFooter>
                  </Dialog>
                </div>
              )}
              <ToastContainer />
            </div>

            <div className="flex gap-5 relative mt-10">
              {state.files?.map(
                (file, index) =>
                  typeof file === "string" && (
                    <div className="w-20 h-20 " key={file}>
                      {!index && (
                        <p className="absolute font-semibold text-lg -mt-10">
                          Attatched Documents
                        </p>
                      )}
                      <img
                        src={"https://" + file.replace("/home4/ampeccom/", "")}
                        className="border h-full w-full object-cover hover:scale-110"
                        placeholder={file}
                        // onClick={() => openModal(file)}
                        onClick={() =>
                          window.open(
                            "https://" + file.replace("/home4/ampeccom/", ""),
                            "_blank"
                          )
                        }
                        alt=""
                      />
                    </div>
                  )
              )}
            </div>
            {modalVisible && (
              <div className="fixed inset-0 flex items-center justify-center z-50">
                <div className="absolute inset-0 bg-black opacity-50"></div>
                <div className="relative z-10 bg-white p-4 rounded-lg shadow-lg">
                  <img
                    src={
                      "https://" + selectedImage.replace("/home4/ampeccom/", "")
                    }
                    alt=""
                    className="max-w-full max-h-screen"
                  />
                  <button
                    className="absolute top-2 right-2 text-red-400"
                    onClick={closeModal}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            )}
            <ToastContainer />
          </div>
        }
      />
      <Route path="/login" element={<Login />} />
      <Route
        path="/register"
        element={
          <>
            <Navbar />
            <Register />
          </>
        }
      />
      <Route
        path="/history"
        element={
          <>
            <Navbar />
            <History />
          </>
        }
      />
      <Route
        path="/trash"
        element={
          <>
            <Navbar />
            <Trash />
          </>
        }
      />
    </Routes>
  );
}

export default App;
