import React from "react";
import { Textarea, Input } from "@material-tailwind/react";
import CheckBox from "./CheckBox";
import { useAppState } from "../AppContext";

const CorrectiveAction = () => {
  const { state, dispatch } = useAppState();

  return (
    <div className="mt-6 w-full space-y-2">
      <p className="italic font-semibold  text-base">
        Corrective Actions to Fix / Contain the Problem
      </p>
      <div className="flex gap-5 pt-4">
        <Textarea
          value={
            state.contain_problem === "null" ? "" : state.contain_problem || ""
          }
          onChange={(e) =>
            dispatch({ type: "CONTAIN_PROBLEM", payload: e.target.value })
          }
          color="pink"
          label="Containment Action"
          labelProps={{
            className: `${
              state.contain_problem
                ? ""
                : "print:peer-placeholder-shown:text-gray-100/40"
            }`,
          }}
          containerProps={{
            className: "flex-1 h-60",
          }}
        />
        <div className="space-y-2 w-[230px]">
          <p className="font-semibold">Effectiveness (QA use only)</p>
          {!state.disable ||
          (state.disable && Number(state.effectiveness) === 1) ? (
            <div className="flex gap-2">
              <CheckBox
                change={() => dispatch({ type: "EFFECTIVENESS", payload: 1 })}
                check={Number(state.effectiveness) === 1}
              />
              <p>Action(s) Accepted</p>
            </div>
          ) : null}
          {!state.disable ||
          (state.disable && Number(state.effectiveness) === 2) ? (
            <div className="flex gap-2 pb-2">
              <CheckBox
                change={() => dispatch({ type: "EFFECTIVENESS", payload: 2 })}
                check={Number(state.effectiveness) === 2}
              />
              <p>Action(s) Implemented</p>
            </div>
          ) : null}
          <Textarea
            value={state.reference === "null" ? "" : state.reference || ""}
            color="pink"
            label="Reference"
            labelProps={{
              className: `${
                state.reference
                  ? ""
                  : "print:peer-placeholder-shown:text-gray-100/40"
              }`,
            }}
            onChange={(e) =>
              dispatch({ type: "REFERENCE", payload: e.target.value })
            }
          />
        </div>
      </div>
      <div className="flex gap-5 items-center pt-2">
        <p>Target to Complete</p>
        <Input
          value={state.complete_date || ""}
          onChange={(e) =>
            dispatch({ type: "COMPLETE_DATE", payload: e.target.value })
          }
          type="date"
          color="pink"
          label="Date"
          className={`${
            state.complete_date ? "" : "print:text-gray-100/40 text-gray-400"
          }`}
          containerProps={{
            className: "flex-1",
          }}
        />
        <div className="flex gap-2 w-[230px]">
          <CheckBox
            change={() =>
              dispatch({
                type: "FURTHER_ACTION",
                payload: state.further_action === "yes" ? "no" : "yes",
              })
            }
            check={state.further_action === "yes"}
          />
          <p>Further Action Needed</p>
        </div>
      </div>
      <div className="flex gap-5 pt-2">
        <Input
          value={
            state.accepted_name === "null" ? "" : state.accepted_name || ""
          }
          onChange={(e) =>
            dispatch({ type: "ACCEPTED_NAME", payload: e.target.value })
          }
          color="pink"
          label="Accepted By (Name)"
          labelProps={{
            className: `${
              state.originator
                ? ""
                : "print:peer-placeholder-shown:text-gray-100/40"
            }`,
          }}
          containerProps={{
            className: "flex-1",
          }}
        />
        <Input
          value={state.accepted_date || ""}
          onChange={(e) =>
            dispatch({ type: "ACCEPTED_DATE", payload: e.target.value })
          }
          color="pink"
          label="Date"
          className={`${
            state.accepted_date ? "" : "print:text-gray-100/40 text-gray-400"
          }`}
          type="date"
          containerProps={{
            className: "flex-1",
          }}
        />
        <Input
          value={state.sign === "null" ? "" : state.sign || ""}
          onChange={(e) => dispatch({ type: "SIGN", payload: e.target.value })}
          color="pink"
          label="Sign"
          labelProps={{
            className: `${
              state.originator
                ? ""
                : "print:peer-placeholder-shown:text-gray-100/40"
            }`,
          }}
          containerProps={{
            className: "w-[230px]",
          }}
        />
      </div>
    </div>
  );
};

export default CorrectiveAction;
