import { useState } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from "react-router-dom";
import {
  Card,
  Input,
  Checkbox,
  Button,
  Typography,
} from "@material-tailwind/react";
import { Link } from "react-router-dom";

const Register = () => {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [role, setRole] = useState("customer");

  const handleRegister = async () => {
    const formData = new FormData();
    formData.append("name", name);
    formData.append("email", email);
    formData.append("password", password);
    formData.append("user_type", role);
    const res = await fetch("https://backendncr.ampectech.com/api/register", {
      method: "POST",
      body: formData,
    });

    const data = await res.json();

    if (data.status === "success") {
      toast.success("User registered successfully!");
      setName("");
      setEmail("");
      setPassword("");
      setRole("customer");
    } else {
      alert(data.message);
    }
  }

  return (
    <div className="w-full flex min-h-screen items-center justify-center relative">
      <Link to='/' className="absolute top-20 left-10 text-gray-500 hover:text-black">/Home</Link>
      <Card color="transparent" shadow={false}>
        <Typography variant="h4" color="blue-gray" className="text-center pb-6">
          Sign Up
        </Typography>
        <Typography color="gray" className="mt-1 font-normal">
          Enter details to register.
        </Typography>
        <form className="mt-8 mb-2 w-80 max-w-screen-lg sm:w-96">
          <div className="mb-4 flex flex-col gap-6">
            <Input size="lg" label="Name" value={name} onChange={e => setName(e.target.value)}/>
            <Input size="lg" label="Email" value={email} onChange={e => setEmail(e.target.value)}/>
            <Input type="password" size="lg" label="Password" value={password} onChange={e => setPassword(e.target.value)} onKeyDown={e => {if(e.key === "Enter") handleRegister()}}/>
          </div>
          <div className="flex gap-10 items-center">
            <p>User type :</p>
            <Checkbox
            checked={role === "customer"}
            onChange={() => setRole("customer")}
            label={
              <Typography
                variant="small"
                color="gray"
                className="flex items-center font-normal"
              >
                Customer
              </Typography>
            }
            containerProps={{ className: "-ml-2.5" }}
          />
          <Checkbox
            checked={role === "admin"}
            onChange={() => setRole("admin")}
            label={
              <Typography
                variant="small"
                color="gray"
                className="flex items-center font-normal"
              >
                Admin
              </Typography>
            }
            containerProps={{ className: "-ml-2.5" }}
          />
          </div>
          <Button className="mt-6" fullWidth onClick={handleRegister}>
            Register
          </Button>
        </form>
        <p onClick={() => navigate("/login")} className="font-medium text-right cursor-pointer">Go to sign in page</p>
      </Card>

      <ToastContainer />
    </div>
  );
};

export default Register;
